import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-regular-svg-icons/faHeart';
import ContactIcons from '../Contact/ContactIcons';

const { PUBLIC_URL } = process.env; // set automatically from package.json:homepage

const SideBar = () => (
  <section id="sidebar">
    <section id="intro">
      <Link to="/" className="logo">
        <img src={`${PUBLIC_URL}/images/Meee.jpg`} alt="" />
      </Link>
      <header>
        <h2>Hongda Lin</h2>
        <p><a href="mailto:linhongda77@gmail.com">linhongda77@gmail.com</a></p>
      </header>
    </section>

    <section className="blurb">
      <h2>About</h2>
      <p><font size="+2">Hi! </font>
        I&apos;m Hongda Lin.
        A grad student in Computer Science at Northwestern University.
        I&apos;m interested in exploring Web3D, AI-powered Assistant Development,
        and other emerging technologies.
      </p>
      <ul className="actions">
        <li>
          {!window.location.pathname.includes('/resume') ? <Link to="/resume" className="button">Learn More</Link> : <Link to="/about" className="button">About Me</Link>}
        </li>
      </ul>
    </section>

    <section id="footer">
      <ContactIcons />
      <p className="copyright">
        &copy; <Link to="/">Hongda Lin&apos;s Portfolio</Link> <FontAwesomeIcon icon={faHeart} beat style={{ color: '#f50000' }} />  2022 - 2023.
      </p>
    </section>
  </section>
);

export default SideBar;
